<template>
  <div>
    <h2 class="mb-2">รูปอนุมัติไปแล้ว</h2>
    <table-server-side-v-1-widget
      :columns="columns"
      :actions="actionsTable"
      url="api/admin/admin-image-approve-server-side?status=approve&filterType=image"
      @clickView="onClickMoreShortDetailsImage"
      @clickViewAndReview="onClickMoreDetailsImage"
      @clickViewListTimeline="onClickMoreTransactionReview"
      @clickResendNotification="onClickResendNotification"
    >
      <template #image_preview="{row}">
        <div @click="gZoomImageFromUrl(row.image_preview)" class="ct-cursor-zoom">
          <b-img-lazy :src="row.image_preview" :alt="row.name" width="100" />
        </div>
      </template>

      <template #detail="{row}">
        <div class="row" style="max-width: 200px">
          <div class="col-12 text-truncate">
            {{ row.detail }}
          </div>
        </div>
      </template>
    </table-server-side-v-1-widget>

    <b-modal
      id="modal-preview-images"
      ref="modal-preview-images"
      size="xl"
      hide-footer
      body-class="p-0"
      title="รูปทั้งหมด"
    >
      <ImagesPreviewSlide :listData="imagePreviewList" />
    </b-modal>

    <b-modal id="modal-review-transaction" ref="modal-review-transaction" size="lg" hide-footer title="ประวัติรีวิว">
      <TimelineReviewWidget :listData="listReview" />
    </b-modal>

    <b-modal
      ref="modal-review-short-details"
      size="lg"
      hide-footer
      title="รายละเอียดแบบย่อ"
      @hide="itemDetailsPreview = null"
    >
      <div v-if="itemDetailsPreview">
        <h5>ข้อมูลรูป</h5>
        <hr class="mt-0" />
        <dl class="ml-1">
          <dt>ชื่อรูป :</dt>
          <dd class="ml-1">{{ itemDetailsPreview.name }}</dd>
          <dt>รายละเอียดรูป :</dt>
          <dd class="ml-1">
            <pre class="custom-pre-text-details">{{ itemDetailsPreview.detail }}</pre>
          </dd>
          <dt>จำนวนรูป :</dt>
          <dd class="ml-1">{{ itemDetailsPreview.count_image }}</dd>
          <dt>ขนาดรูป :</dt>
          <dd class="ml-1">
            {{ widthHeightImagePreview(itemDetailsPreview.json_data) }}
          </dd>
        </dl>
        <br />
        <h5>ข้อมูลร้านค้า</h5>
        <hr class="mt-0" />
        <dl v-if="itemDetailsPreview.user" class="ml-1">
          <dt>ชื่อร้าน :</dt>
          <dd class="ml-1">{{ itemDetailsPreview.user.shop_name }}</dd>
          <dt>รายได้ :</dt>
          <dd class="ml-1">{{ gFormatNumberToCurrency(itemDetailsPreview.user.credit_balance) }}</dd>
          <dt>ลงทะเบียนร้านค้าเมื่อ :</dt>
          <dd class="ml-1">
            {{ gFormatDate(itemDetailsPreview.user.created_at, true) }}
          </dd>
        </dl>
        <p v-else class="text-muted mb-50">
          ไม่พบข้อมูล
        </p>
        <br />
        <h5>ประวัติรีวิว</h5>
        <hr class="mt-0" />
        <div v-if="itemDetailsPreview.image_timeline && itemDetailsPreview.image_timeline.length !== 0">
          <b-badge :variant="csFormat.formatVariantStatusBadge(itemDetailsPreview.image_timeline[0].status_data)">
            {{ csFormat.formatStatusBadge(itemDetailsPreview.image_timeline[0].status_data) }}
          </b-badge>

          <small class="text-muted d-block mt-50">
            วันที่รีวิวล่าสุด {{ gFormatDate(itemDetailsPreview.image_timeline[0].created_at) }}
          </small>

          <p>
            {{ itemDetailsPreview.image_timeline[0].note }}
          </p>
          <b-button
            variant="primary"
            size="sm"
            block
            class="mt-50"
            @click="onClickMoreTransactionReview(itemDetailsPreview)"
          >
            ดูประวัติการรีวิวทั้งหมด
          </b-button>
        </div>
        <p v-else class="text-muted mb-50">
          ไม่พบประวัติการรีวิว
        </p>
      </div>
      <p v-else class="text-center text-muted mt-2">
        ไม่พบข้อมูล
      </p>
    </b-modal>
  </div>
</template>

<script>
import TimelineReviewWidget from '../../../../../widgets/TimelineReviewWidget.vue'
import ImagesPreviewSlide from '../components/ImagesPreviewSlide.vue'

export default {
  components: { TimelineReviewWidget, ImagesPreviewSlide },
  data() {
    return {
      columns: [
        {
          label: 'รูป',
          field: 'image_preview',
          type: 'custom',
          filterable: false,
          sortable: false,
          filterOptions: {
            enabled: false,
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'ชื่อรูป',
          field: 'name',
          sortable: false,
        },
        {
          label: 'รายละเอียดรูป',
          field: 'detail',
          type: 'custom',
          sortable: false,
        },
        {
          label: 'จำนวนรูป',
          field: 'count_image',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'ชื่อร้าน',
          field: 'user.shop_name',
        },
        {
          label: 'วันที่ส่งรีวิว (วัน-เดือน-ปี)',
          field: 'updated_at',
          formatFn: val => this.$date(val).format('DD-MM-YYYY HH:mm'),
        },
      ],
      actionsTable: [
        { keyEmit: 'clickView', label: 'ดูรายละเอียดแบบย่อ', iconName: 'EyeIcon' },
        { keyEmit: 'clickViewAndReview', label: 'ดูเพิ่มเติม&รีวิว', iconName: 'EyeIcon' },
        { keyEmit: 'clickResendNotification', label: 'ส่งแจ้งเตือนอีกครั้ง', iconName: 'SendIcon' },
      ],
      itemDetailsPreview: null,
      listReview: [],
      imagePreviewList: [],
    }
  },
  computed: {
    widthHeightImagePreview() {
      return item => {
        try {
          const jsonData = JSON.parse(item)

          const { naturalWidth, naturalHeight } = jsonData.file

          return `${naturalWidth}w x ${naturalHeight}h`
        } catch (error) {
          return 'พบข้อผิดพลาด'
        }
      }
    },
  },
  methods: {
    onClickMoreTransactionReview(data) {
      // console.log('onClickMoreTransactionReview => ', data)
      this.listReview = [...data.image_timeline]
      this.$refs['modal-review-transaction'].show()
    },
    onClickPreviewImage(data) {
      // console.log('onClickPreviewImage => ', data)
      this.imagePreviewList = data
      this.$refs['modal-preview-images'].show()
    },
    onClickMoreDetailsImage(data) {
      // console.log('onClickMoreDetailsImage => ', data)
      this.$router.push({ name: 'images-management-details', params: { id: data.id ?? 0, canReview: false } })
    },
    onClickMoreShortDetailsImage(data) {
      // console.log('onClickMoreShortDetailsImage', data)
      this.itemDetailsPreview = data
      this.$refs['modal-review-short-details'].show()
    },
    async onClickResendNotification(data) {
      const isConfirm = await this.gCheckConfirmV1('ยืนยันส่ง Notification อีกครั้ง')
      if (!isConfirm) return

      this.gOpenPageLoading()
      const resp = await this.api.postV2(
        '/api/admin/notification-data/resend-topic-image-data',
        { topic_image_data: data.id },
        this,
      )
      if (resp && resp.code === 200) {
        this.gDisplayToast('ส่ง Notification สำเร็จ')
      } else {
        this.gDisplayToast('ส่ง Notification ไม่สำเร็จ', resp?.data?.messgae, 'danger')
      }

      this.gClosePageLoading()
    },
  },
}
</script>

<style lang="scss" scoped></style>
